@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: rgb(255, 255, 255)
  
}

* {
  margin: 0;
  padding: 0;
  
}

  .preloader {
    position: fixed;
    z-index: 100;
    align-items: center;
    background-color: black;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
    margin-top: -10px;
    
  }

  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0.5;
      transform: scale(1);
    }
  }



    /*--Glass--*/
    .glass{
      margin-left: 50px;
      margin-right: 50px;
      margin-top: 20px;
      margin-bottom: 20px;
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      border-radius: 20px;
      border:1px solid rgba(255, 255, 255, 0.18);
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
      @media only screen and (max-width: 600px) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    /*--Glass End--*/
  